import {db} from "../firebaseconfig";
import {ACTUAL_FORM} from "../commons/Globals";
import {updateDoc, arrayUnion, doc, getDoc} from "firebase/firestore";

export const getRegistrations = (event, callback) => {
  const registrationsRef = doc(db, event, "registrations");
  getDoc(registrationsRef)
    .then(docSnap => {
      if (docSnap.exists()) {
        callback(docSnap.data().list);
      } else {
        callback([])
      }
    })
    .catch(error => console.log("Error in getRegistrations", error))
}

export const addRegistration = (registration, callback) => {
  const registrationsRef = doc(db, ACTUAL_FORM, "registrations");
  updateDoc(registrationsRef, { list: arrayUnion(registration)})
    .then(res => console.log("registration successfully saved"))
    .catch(error => console.log("Error in addRegistration: ", error))
}
