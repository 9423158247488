
const FORM_GV_2023 = "gv-2023";

export const ACTUAL_FORM=FORM_GV_2023
export const BUTTON_BACK_VARIANT = "btn-outline-dark"

export const CHOOSE=0
export const JA=1
export const NEIN=2
export const getTextForYesNo = (id) => {
  return id===JA ? "Ja" : id===NEIN ? "Nein" : "Nein"
}

export const NO_HOTEL=99
export const ROOM = {
  NONE: { code: "none", text: "kein Zimmer" },
  EZ: { code: "EZ", text: "Einzelzimmer" } ,
  DZ: { code: "DZ", text: "Doppelzimmer" } ,
}

export const hotelsMeeting = [
  { id:6, name: "Hotel Kursaal Bern", ez: 10, dz: 10, price: "255.-" }
]

export const getHotelFromId = (id, hotels) => {
  if (id === NO_HOTEL) return "kein Hotel"
  const hotel = hotels.filter(hotel => hotel.id==id)
  return hotel && hotel.length>0 ? hotel[0].name : "kein Hotel"
}
export const getHotelFromIdMeeting = (id) => {
  const hotel = hotelsMeeting.filter(hotel => hotel.id==id)
  return hotel && hotel.length>0 ? hotel[0].name : "kein Hotel"
}
export const getRoomFromId = (id) => {
  return id===ROOM.EZ.code ? ROOM.EZ.text : id===ROOM.DZ.code ? ROOM.DZ.text : ROOM.NONE.text
}
