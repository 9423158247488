import {useEffect, useState} from "react";
import {getItem, setItem} from "../../../commons/persistence";
import FSKBInputField from "../../../components/FSKBInputField";
import FSKBButton from "../../../components/FSKBButton";
import FSKBTitle from "../../../components/FSKBTitle";

export default function CodeStep({isCodeValid}) {
  const MAIN_CODE = "0815"
  const [code, setCode] = useState("");

  useEffect(() => {
    let _code = getItem("@Code")
    if (_code === MAIN_CODE) {
      setCode(_code)
      isCodeValid(true)
    } else {
      isCodeValid(false)
    }
  }, [])

  const handleCodeCheckButton = (e) => {
    e.preventDefault();
    isCodeValid(code === MAIN_CODE)
    if (code === MAIN_CODE) {
      setItem("@Code", MAIN_CODE)
    }
  }

  return (
    <div className="col">
      <FSKBTitle title={"Anmeldung Kies- und Betontage 2023"}
                 titleFranz={"Inscription Journées gravier et béton 2023"} />

      <p>
        Bitte geben Sie den Code ein:<br/>
        <span className="franz">Veuillez saisir le code:</span>
      </p>

      <form>
        <FSKBInputField title="code"
                        titleFranz="code"
                        keyboardType="password"
                        onChangeText={value => setCode(value) }
                        required={true}
                        requiredFunction={() => code === MAIN_CODE}
        />

        <div className="footer-buttons">
          <FSKBButton title="anmelden / enregistrer" onClick={handleCodeCheckButton} />
        </div>
      </form>
    </div>
  )
}
