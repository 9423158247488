import {useEffect, useState} from "react";
import {addRegistration} from "../../services/registrationService";
import CodeStep from "./wizard/CodeStep";
import WizardStep1Explanations from "./wizard/WizardStep1Explanations";
import WizardStep2PersonalData from "./wizard/WizardStep2PersonalData";
import WizardStep3Program from "./wizard/WizardStep3Program";
import WizardStep4Hotels from "./wizard/WizardStep4Hotels";
import WizardStep5Specials from "./wizard/WizardStep5Specials";
import WizardStep6Summary from "./wizard/WizardStep6Summary";
import WizardStep7End from "./wizard/WizardStep7End";
import {sendMail} from "../../services/mailService";
import {getHotels} from "../../services/hotelService";
import {ACTUAL_FORM, CHOOSE, NO_HOTEL, ROOM} from "../../commons/Globals";

const dataInit = {
  registration: {
    date: null
  },
  personalDetails: {
    firstname: '',
    lastname: "",
    company: '',
    street: '',
    zipCode: '',
    city: '',
    email: '',
    phone: '',
    invoiceAddress: '---',
    costCenter: '---'
    /*
    firstname: 'Peter',
    lastname: "Muster",
    company: '---',
    street: 'Musterstrasse 12',
    zipCode: '8000',
    city: 'Musterhausen',
    email: 'peter@muster.ch',
    phone: '0000000000',
    invoiceAddress: '---',
    costCenter: '---'*/
  },
  program: {
    question1: CHOOSE,
    question2: CHOOSE,
    question3: CHOOSE,
    question4: CHOOSE,
    question5: CHOOSE,
    question6: CHOOSE,
    remarks: ""
  },
  hotel: {
    id: CHOOSE,
    room: "none"
  },
  meeting: {
    meeting1: CHOOSE,
    meeting2: CHOOSE,
    dinner: CHOOSE,
    hotel: { id: NO_HOTEL, room: ROOM.NONE.code }
  }
}


export default function MainForm() {
  const [step, setStep] = useState("CODE");
  const [data, setData] = useState(dataInit)
  const [hotels, setHotels] = useState([])

  useEffect(() => {
    getHotels(ACTUAL_FORM, (hotels) => setHotels(hotels))
  }, [])

  useEffect(() => {
    //console.log("Data changed: ", data);
  },[data])

  return (
    <>
      <div className="blue-bar"></div>
      <div className="container">
        <div className="row">

          {/*<FireBaseView />*/}

          { step === "CODE" &&
            <CodeStep isCodeValid={ (isCodeValid) => {
                if (isCodeValid) {
                  setStep("WS1")
                }
            }} />
          }

          { step === "WS1" &&
            <WizardStep1Explanations onClick={() => { setStep("WS2") }} />
          }

          { step === "WS2" &&
            <WizardStep2PersonalData
              data={data.personalDetails}
              onClick={ (formData) => {
                setData({ ...data, personalDetails: formData })
                setStep("WS3")
              }}
              onClickBack={ () => setStep("WS1") }
            />
          }

          { step === "WS3" &&
          <WizardStep3Program
            data={data.program}
            onClick={ (formData) => {
              setData({ ...data, program: formData })
              setStep("WS4")
            }}
            onClickBack={ () => setStep("WS2") }
          />
          }

          { step === "WS4" &&
            <WizardStep4Hotels
              data={data.hotel}
              onClick={ (formData) => {
                setData({  ...data, hotel: formData })
                setStep("WS5")
              }}
              onClickBack={ () => setStep("WS3") }
              hotels={hotels}
            />
          }

          { step === "WS5" &&
            <WizardStep5Specials
              data={data.meeting}
              onClick={ (formData) => {
                setData({ ...data, meeting: formData })
                setStep("WS6")
              }}
              onClickBack={ () => setStep("WS4") }
            />
          }

          {
            step === "WS6" &&
            <WizardStep6Summary
              data={data}
              onClick={() => {
                data.registration.date = (new Date()).toString();
                addRegistration(data)
                sendMail(
                  "rlanza@clip.ch",
                  "Anmeldung an die Kies- und Betontage 2023 / Inscription pour les journées Gravier et Béton 2023",
                  data,
                  hotels
                )
                sendMail(
                  "patricia.spuehler@fskb.ch",
                  "Anmeldung an die Kies- und Betontage 2023 / Inscription pour les journées Gravier et Béton 2023",
                  data,
                  hotels
                )
                setStep("WS7")
              }}
              onClickBack={() => setStep("WS5") }
              hotels={hotels}
            />
          }

          {
            step === "WS7" && <WizardStep7End data={data}
                                              onClickBack={()=> setStep("WS6")}/>
          }

        </div>
      </div>
    </>
  )
}

