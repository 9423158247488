import React from "react";
import { baseColors } from "../commons/Theme";

export default function FSKBInputField({
    title, titleFranz, placeholder, value,
    onChangeText, keyboardType, requiredFunction }) {
  const id = title + 'id'

  function borderColor() {
    return (requiredFunction && !requiredFunction()) ? '#f00' : '#ccc'
  }

  return (
    <div style={styles.view}>
      <label htmlFor={id} className="form-label">
        <strong>{title} / <span className='franz'>{titleFranz}</span></strong>
      </label>
      <input
        id={id}
        style={styles.input}
        onChange={e => onChangeText(e.target.value)}
        value={value}
        placeholder={placeholder}
        type={keyboardType ? keyboardType : 'text'}
        className={'form-control'}
        style={{borderColor: borderColor()}}
      />
    </div>
  )
}

const styles = {
  view: {
    marginTop: 2,
    marginBottom: 10,
    padding: 0,
  },
  title: {
    color: '#072B4D'
  },
  input: {
    height: 48,
    borderWidth: 1,
    borderColor: baseColors.background,
    backgroundColor: 'white'
  },
}


