
// ------------

let observerList = []

export const registerObserver = (name, observer) => {
  observerList.push({name, observer})
}
export const unregisterObserver = (name) => {
  observerList = observerList.filter(o => o.name === name)
}
const informObservers = (data) => {
  observerList.forEach(o => o.observer(data))
}

// ------------

export const getItem = (name) => {
  return sessionStorage.getItem(name)
}

export const setItem = (name, value) => {
  sessionStorage.setItem(name, value)
  informObservers(value)
}

export const clearItem = (name) => {
  sessionStorage.removeItem(name)
  informObservers(null)
}
