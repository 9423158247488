import {useState} from "react";
import {isSignedIn, signInUser} from "../../services/authenticationService";
import FSKBButton from "../../components/FSKBButton";

export default function LoginView({callback}) {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const signIn = (e) => {
    e.preventDefault();
    signInUser(username, password, (accessToken) => {
    })
  }
  return (
    <div className="row">
      <div className="col-4"></div>
      <div className="col">
        <div className="text-center">
          <h1 style={{paddingTop: 40, paddingBottom: 20}}>Anmeldung</h1>
        </div>
        <form>
          <div className="form-outline mb-4">
            <input type="email" id="form2Example1"
                   className="form-control"
                   value={username}
                   onChange={(e)=>setUsername(e.target.value)}
            />
            <label className="form-label" htmlFor="form2Example1">
              E-Mail Adresse
            </label>
          </div>
          <div className="form-outline mb-4">
            <input type="password" id="form2Example2"
                   className="form-control"
                   value={password}
                   onChange={(e)=>setPassword(e.target.value)}
            />
            <label className="form-label" htmlFor="form2Example2">
              Passwort
            </label>
          </div>
          <FSKBButton title="anmelden" onClick={signIn} />
        </form>
      </div>
      <div className="col-4"></div>
    </div>
  )
}