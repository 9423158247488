import {useState} from "react";
import FSKBInputField from "../../../components/FSKBInputField";
import FSKBButton from "../../../components/FSKBButton";
import {BUTTON_BACK_VARIANT} from "../../../commons/Globals";
import FSKBTitle from "../../../components/FSKBTitle";
import FSKBMessage from "../../../components/FSKBMessage";

export default function WizardStep2PersonalData({ data, onClick, onClickBack }) {
  const [lastname, setLastname] = useState(data.lastname);
  const [firstname, setFirstname] = useState(data.firstname);
  const [company, setCompanyName] = useState(data.company);
  const [street, setStreet] = useState(data.street);
  const [zipCode, setZipCode] = useState(data.zipCode);
  const [city, setCity] = useState(data.city);
  const [email, setEmail] = useState(data.email);
  const [phone, setPhone] = useState(data.phone);
  const [invoiceAddress, setInvoiceAddress] = useState(data.invoiceAddress);
  const [costCenter, setCostCenter] = useState(data.costCenter);
  const [message, setMessage] = useState(null)

  const validateLastname = () => lastname.length >= 2
  const validateFirstname = () => firstname.length >= 2
  const validateEnterprise = () => company.length >= 2
  const validateStreet = () => street.length >= 6
  const validateZipCode = () => {
    if (isNaN(zipCode)) return false
    if (zipCode.toString().length !== 4) return false
    if (zipCode < 1000 || zipCode > 9999) return false
    return true;
  }
  const validateCity = () => city.length >= 2
  const validateEmail = () => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }
  const validatePhone = () => {
    const regex = /^(\+41|0)(\s*\d){9}$/;
    return regex.test(phone);
  }

  return (
    <div className="col">
      <FSKBTitle title={"Personalangaben"} titleFranz={"Données personnelles"} />

      <FSKBInputField title={'Name'}
                      titleFranz={'Nom'}
                      placeholder={"Nachnamen eingeben"}
                      value={lastname}
                      onChangeText={v => setLastname(v)}
                      requiredFunction={validateLastname} />
      <FSKBInputField title={'Vorname'}
                      titleFranz={'Prénom'}
                      placeholder={"Vornamen eingeben"}
                      value={firstname}
                      onChangeText={v => setFirstname(v)}
                      requiredFunction={validateFirstname} />
      <FSKBInputField title={'Firma'}
                      titleFranz={'Entreprise'}
                      placeholder={"Firma eingeben"}
                      value={company}
                      onChangeText={v => setCompanyName(v)}
                      requiredFunction={validateEnterprise} />
      <FSKBInputField title={'Strasse'}
                      titleFranz={'Rue'}
                      placeholder={"Strasse und Nummer eingeben"}
                      value={street}
                      onChangeText={v => setStreet(v)}
                      requiredFunction={validateStreet} />
      <FSKBInputField title={'PLZ'}
                      titleFranz={'NP'}
                      placeholder={"PLZ eingeben"}
                      value={zipCode}
                      onChangeText={v => setZipCode(v)}
                      requiredFunction={validateZipCode} />
      <FSKBInputField title={'Ort'}
                      titleFranz={'Lieu'}
                      placeholder={"Ort eingeben"}
                      value={city}
                      onChangeText={v => setCity(v)}
                      requiredFunction={validateCity} />
      <FSKBInputField title={'E-Mail'}
                      titleFranz={'E-Mail'}
                      placeholder={"E-Mail Adresse eingeben"}
                      value={email}
                      onChangeText={ v => setEmail(v)}
                      requiredFunction={validateEmail} />
      <FSKBInputField title={'Telefonnummer'}
                      titleFranz={'No de téléphone'}
                      placeholder={"Telefonnummer eingeben"}
                      value={phone}
                      onChangeText={ v => setPhone(v)}
                      requiredFunction={validatePhone} />
      <FSKBInputField title={'Rechnungsadresse oder Rechnungs-E-Mail'}
                      titleFranz={'Adresse de facturation (si l’adresse diffère de l’adresse de l’entreprise)'}
                      placeholder={"Rechnungsadresse oder Rechnungs-E-Mail eingeben"}
                      value={invoiceAddress}
                      onChangeText={v => setInvoiceAddress(v)} />
      <FSKBInputField title={'Kostenstelle, Vermerk auf Rechnung'}
                      titleFranz={'Centre de coûts – informations'}
                      placeholder={"Kostenstelle eingeben"}
                      value={costCenter}
                      onChangeText={v => setCostCenter(v)} />

      <div className={"footer-buttons"}>
        <FSKBMessage message={message} />

        <FSKBButton title="zurück / retour" onClick={onClickBack} variant={BUTTON_BACK_VARIANT}/>
        <FSKBButton title="weiter / suivant" onClick={ () => {
          if (validateLastname() && validateFirstname() && validateEnterprise() &&
              validateStreet() && validateZipCode() && validateCity() &&
              validateEmail() && validatePhone()) {
            onClick( {
              lastname, firstname, company, street, zipCode, city,
              email, phone, invoiceAddress, costCenter
            })
          } else {
              setMessage(
                "Bitte füllen Sie alle Felder korrekt aus / " +
                "Veuillez remplir tous les champs correctement.")
          }
        }
        } />
      </div>
    </div>
  )
}
