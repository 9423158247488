import {useEffect, useState} from "react";
import {ACTUAL_FORM, BUTTON_BACK_VARIANT, getTextForYesNo, hotelsMeeting} from "../../../commons/Globals";
import FSKBButton from "../../../components/FSKBButton";
import {getHotels} from "../../../services/hotelService";
import {getHotelFromId,getHotelFromIdMeeting,getRoomFromId} from "../../../commons/Globals"
import {getQuestion6Answer} from "./WizardStep3Program";
import FSKBTitle from "../../../components/FSKBTitle";

export default function WizardStep5Summary({ data, onClick, onClickBack, hotels }) {

  return (
    <div className="col">

      <FSKBTitle title={"Zusammenfassung"}
                 titleFranz={"Résumé"} />

      <strong>Personalangaben</strong>
      <p>Bitte überprüfen Sie Ihre Angaben.</p>

      <div className="row">
        <div className="col-8 alert alert-dark">
          <strong>
            Anmeldung Kies- und Betontage 2023<br/>
            Inscription Journées gravier et béton 2023
          </strong>
          <table className="table">
            <tbody>
            <tr>
              <td>Name / Nom:</td>
              <td><strong>{data.personalDetails.lastname}</strong></td>
            </tr>
            <tr>
              <td>Vorname / Prénom:</td>
              <td><strong>{data.personalDetails.firstname}</strong></td>
            </tr>
            <tr>
              <td>Firma / Entreprise:</td>
              <td><strong>{data.personalDetails.company}</strong></td>
            </tr>
            <tr>
              <td>Strasse / Rue:</td>
              <td><strong>{data.personalDetails.street}</strong></td>
            </tr>
            <tr>
              <td>PLZ / NP:</td>
              <td><strong>{data.personalDetails.zipCode}</strong></td>
            </tr>
            <tr>
              <td>Ort / Lieu:</td>
              <td><strong>{data.personalDetails.city}</strong></td>
            </tr>
            <tr>
              <td>E-Mail:</td>
              <td><strong>{data.personalDetails.email}</strong></td>
            </tr>
            <tr>
              <td>Telefonnummer / No de téléphone:</td>
              <td><strong>{data.personalDetails.phone}</strong></td>
            </tr>
            <tr>
              <td>
                Rechnungsadresse oder Rechnungs-E-Mail<br/>
                Adresse de facturation:<br/>
                <small>(si l’adresse diffère de l’adresse de l’entreprise)</small>
              </td>
              <td><strong>{data.personalDetails.invoiceAddress}</strong></td>
            </tr>
            <tr>
              <td>
                Kostenstelle, Vermerk auf Rechnung:<br/>
                Centre de coûts – informations
              </td>
              <td><strong>{data.personalDetails.costCenter}</strong></td>
            </tr>
            <tr>
              <td>Hotelzimmer für die Tagung:</td>
              <td>
                <strong>
                  {getHotelFromId(data.hotel.id, hotels)}<br/>
                  {getRoomFromId(data.hotel.room)}
                </strong>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <br/>

      <div className="row">
        <div className="col-8 alert alert-dark">
          <strong>Auswahl Programm</strong>
          <table className="table">
            <tbody>
            <tr>
              <td>Teilnahme an der MV SÜGB</td>
              <td><strong>{getTextForYesNo(data.program.question1)}</strong></td>
            </tr>
            <tr>
              <td>Teilnahme am Mittagessen Freitag</td>
              <td><strong>{getTextForYesNo(data.program.question2)}</strong></td>
            </tr>
            <tr>
              <td>Teilnahme an der MV FSKB</td>
              <td><strong>{getTextForYesNo(data.program.question3)}</strong></td>
            </tr>
            <tr>
              <td>Teilnahme am Aperitif Freitagabend</td>
              <td><strong>{getTextForYesNo(data.program.question4)}</strong></td>
            </tr>
            <tr>
              <td>Teilnahme am Abendessen Freitag</td>
              <td><strong>{getTextForYesNo(data.program.question5)}</strong></td>
            </tr>
            <tr>
              <td>Teilnahme am Ausflug am Samstag</td>
              <td><strong>{getQuestion6Answer(data.program.question6)}</strong></td>
            </tr>
            <tr>
              <td>
                Bemerkungen:
                <br/>
                <strong>{data.program.remarks}</strong>
              </td>
              <td></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <br/>

      <div className="row">
        <div className="col-8 alert alert-dark">
          <strong>
            Teilnahme Vorstand / Präsidenten- und Geschäftsführerkonferenz<br/>
            Participation séance comité / Conférence des présidents et directeurs
          </strong>
          <table className="table">
            <tbody>
            <tr>
              <td>
                Teilnahme an der Vorstandssitzung:<br/>
                Participation à la séance du comité ASGB<br/>
                <small>(uniquement pour membres du comité ASGB)</small>
              </td>
              <td><strong>{getTextForYesNo(data.meeting.meeting1)}</strong></td>
            </tr>
            <tr>
              <td>
                Teilnahme an der Präsidenten- und Geschäftsführerkonferenz:<br/>
                Participation séance comité / Conférence des présidents et directeurs<br/>
                <small>(uniquement pour membres du comité ASGB et présidents et directeurs des associations cantonales)</small>
              </td>
              <td>
                <strong>{getTextForYesNo(data.meeting.meeting2)}</strong>
              </td>
            </tr>
            <tr>
              <td>
                Teilnahme Abendessen nach der Konferenz<br/>
                Participation au souper aprés la conférence<br/>
              </td>
              <td>
                <strong>{getTextForYesNo(data.meeting.dinner)}</strong>
              </td>
            </tr>

            <tr>
              <td>Hotelzimmer:</td>
              <td>
                <strong>{getHotelFromIdMeeting(data.meeting.hotel.id)}</strong>
                <br/>
                <strong>{getRoomFromId(data.meeting.hotel.room)}</strong>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="footer-buttons">
        <FSKBButton title="zurück / retour" onClick={onClickBack} variant={BUTTON_BACK_VARIANT}/>
        <FSKBButton title="senden / envoyer" onClick={onClick} />
      </div>
    </div>
  )
}
