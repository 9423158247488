
const defaultBorderRadius = 10

export const baseColors = {
  darkGreen: '#224722',
  background: '#bbcbbb',
  textColor: '#5C5C5C',
  primaryColor: '#072B4D',
  white: '#fff'
}
export const baseTitles = {
  title: {
    size: 16,
    color: baseColors.darkGreen,
  },
  subTitle: {
    size: 10,
    color: baseColors.darkGreen,
  },
}

export const baseListElement = {
  borderRadius: defaultBorderRadius,
  title: {
    size: 16,
    color: baseColors.darkGreen,
  },
  text: {
    size: 12,
    color: baseColors.darkGreen,
  },
  smallText: {
    text: 7,
    textColor: baseColors.darkGreen,
  },
}

export const calendarTheme = {
  styles: {
    color: '#fff',
    borderRadius: defaultBorderRadius
  },
  theme: {
    backgroundColor: '#ffffff',
    calendarBackground: '#ffffff',
    textSectionTitleColor: baseColors.primaryColor,
    textSectionTitleDisabledColor: '#d9e1e8',
    selectedDayBackgroundColor: '#00adf5',
    selectedDayTextColor: '#ffffff',
    todayTextColor: '#92df75',
    dayTextColor: '#2d4150',
    textDisabledColor: '#d9e1e8',
    dotColor: '#00adf5',
    selectedDotColor: '#ffffff',
    arrowColor: baseColors.primaryColor,
    disabledArrowColor: '#d9e1e8',
    monthTextColor: baseColors.primaryColor,
    indicatorColor: 'blue',
    /*textDayFontFamily: 'monospace',*/
    /*textMonthFontFamily: 'monospace',*/
    /*textDayHeaderFontFamily: 'monospace',*/
    textDayFontWeight: '300',
    textMonthFontWeight: 'bold',
    textDayHeaderFontWeight: '300',
    textDayFontSize: 16,
    textMonthFontSize: 16,
    textDayHeaderFontSize: 16
  }
}


// ----------------------------
// https://materialdesignicons.com
// ----------------------------
/*
export const IconChevronRight = ({color, size}) => {
    return (
      <MaterialCommunityIcons name="chevron-right" color={color} size={size} />
    )
}
export const ListChevron = () => {
  return (
    <MaterialCommunityIcons
      name="chevron-right"
      color={baseColors.primaryColor}
      size={16} />
  )
}
export const IconDot = ({color, size}) => {
  return (
    <MaterialCommunityIcons name="circle-medium" color={color} size={size} />
  )
}
export const IconUpload = ({color, size}) => {
  return (
    <MaterialCommunityIcons name="tray-arrow-up" color={baseColors.primaryColor} size={30} />
  )
}
export const IconPlusCircle = ({color, size}) => {
  return (
    <MaterialCommunityIcons name="plus-circle" color={baseColors.primaryColor} size={30} />
  )
}

*/
