import {doc, getDoc} from "firebase/firestore";
import {db} from "../firebaseconfig";

// Get data with Firestore
// https://firebase.google.com/docs/firestore/query-data/get-data?hl=en

export const getHotels = (event, callback) => {
  const hotelsRef = doc(db, event, "hotels");
  getDoc(hotelsRef)
    .then(docSnap => {
      if (docSnap.exists()) {
        callback(docSnap.data().list);
      } else {
        callback([])
      }
    })
    .catch(error => console.log("Error in getHotels", error))
}