import {useState} from "react";
import {BUTTON_BACK_VARIANT, JA, NEIN} from "../../../commons/Globals";
import FSKBButton from "../../../components/FSKBButton";
import FSKBInputField from "../../../components/FSKBInputField";
import FSKBTitle from "../../../components/FSKBTitle";
import FSKBMessage from "../../../components/FSKBMessage";

const getUuid = () => 'id-' + Math.floor(Math.random() * 1000000001)

export const YES_NO_QUESTIONS_VALUES = [
  {
    title: 'Teilnahme an der Mitgliederversammlung SÜGB',
    titleFranz: 'Participation à l’assemblée ASMP'
  },
  {
    title: 'Teilnahme am Mittagessen Freitag',
    titleFranz: 'Participation au repas vendredi midi'
  },
  {
    title: 'Teilnahme an der Mitgliederversammlung FSKB',
    titleFranz: 'Participation à l’assemblée ASGB'
  },
  {
    title: 'Teilnahme am Aperitif Freitagabend',
    titleFranz: 'Participation à l’apéritif vendredi soir'
  },
  {
    title: 'Teilnahme am Abendessen Freitag',
    titleFranz: 'Participation au repas vendredi soir'
  }
]

export const QUESTION_6_VALUES = [
  { label: "nein, nehme an keiner Besichtigung teil", labelFranz: "ne participe à aucune visite", value: 1 },
  { label: "Besichtigung Parlamentsgebäude", labelFranz: "Visite Palais du parlement", value: 2 },
  { label: "Besichtigung Energiezentrale Forsthaus", labelFranz: "Visite centrale électrique Forsthaus", value: 3 },
]
export const getQuestion6Answer = (value) => {
  return QUESTION_6_VALUES.filter(v => v.value===value)[0].label
}

function YesNoComponent ({ title, titleFranz, callback, value}) {
    return (
      <MultipleChoiceComponent
        title={title}
        titleFranz={titleFranz}
        callback={callback}
        values={[
          { label: "ja", labelFranz: "oui", value: JA },
          { label: "nein", labelFranz: "non", value: NEIN },
        ]}
        value={value} />
    )
}

function MultipleChoiceComponent ({ title, titleFranz, callback, values, value}) {
  const uuid = getUuid()

  // TODO: Evtl. wäre es besser hier noch das checked Attribut einzufügen
  return (
    <>
      <strong>{title}<br/><span className='franz'>{titleFranz}</span></strong>
      { values.map((entry, idx) =>
        <div className="form-check" key={"mpc-"+idx}>
          <input className="form-check-input" type="radio" name={uuid} checked={entry.value===value}
                 value={entry.value}
                 onChange={e => callback(parseInt(e.target.value)) } />
          <label className="form-check-label" >{entry.label + " / " + entry.labelFranz}</label>
        </div>
      )}
      <br/>
    </>
  )
}

export default function WizardStep3Program({ data, onClick, onClickBack }) {
    const [question1, setQuestion1] = useState(data.question1)
    const [question2, setQuestion2] = useState(data.question2)
    const [question3, setQuestion3] = useState(data.question3)
    const [question4, setQuestion4] = useState(data.question4)
    const [question5, setQuestion5] = useState(data.question5)
    const [question6, setQuestion6] = useState(data.question6)
    const [remarks, setRemarks] = useState(data.remarks)
    const [message, setMessage] = useState(null)

    const updateQuestion = [
      (value) => setQuestion1(value),
      (value) => setQuestion2(value),
      (value) => setQuestion3(value),
      (value) => setQuestion4(value),
      (value) => setQuestion5(value),
    ]
    const getQuestionValue = (idx) => {
      switch (idx) {
        case 0: return question1
        case 1: return question2
        case 2: return question3
        case 3: return question4
        case 4: return question5
        default: return question1
      }
    }

  return (
    <div className="col">
      <FSKBTitle title={"Auswahl Programm"}
                 titleFranz={"Choix du programme"} />

      <div style={{marginTop:2, marginBottom: 20}}>
          <strong>Programm / <span className="franz">Programme</span></strong>
      </div>

      { YES_NO_QUESTIONS_VALUES.map((entry, idx) =>
          <YesNoComponent
            key={"yesNo-" + idx}
            title={entry.title}
            titleFranz={entry.titleFranz}
            callback={ updateQuestion[idx] }
            value={getQuestionValue(idx)} />
        )
      }

      <MultipleChoiceComponent
        title={"Teilnahme am Ausflug Samstagvormittag (inkl. Mittagessen)"}
        titleFranz={"Participation à l’excursion samedi matin (y.c. repas de midi)"}
        callback={value => setQuestion6(value)}
        values={QUESTION_6_VALUES}
        value={question6} />

      <br/>
        <FSKBInputField
          title={'Bemerkungen'}
          titleFranz={'Remarques'}
          value={remarks}
          onChangeText={ value => setRemarks(value)} />

        <div className="footer-buttons">
            <FSKBMessage message={message} />

            <FSKBButton title="zurück / retour" onClick={onClickBack} variant={BUTTON_BACK_VARIANT}/>
            <FSKBButton title="weiter / suivant" onClick={() => {
                if (question1 && question2 && question3 && question4 && question5 && question6) {
                    onClick({
                      question1, question2, question3, question4,
                      question5, question6, remarks,
                      message
                    })
                } else {
                    setMessage(
                      "Bitte beantworten Sie alle Fragen / " +
                      "Veuillez répondre à toutes les questions.")
                }
            }} />
        </div>
    </div>
  )
}


