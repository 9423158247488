import FSKBButton from "../../../components/FSKBButton";
import {BUTTON_BACK_VARIANT} from "../../../commons/Globals";
import FSKBTitle from "../../../components/FSKBTitle";

export default function WizardStep7End({onClickBack}) {
  return (
    <div className="col">
      <FSKBTitle title={"Danke für Ihre Anmeldung"}
                 titleFranz={"Merci pour votre incription"} />

      { /* TODO: Remove this Button */}
      <FSKBButton title="zurück / retour"
                  onClick={onClickBack}
                  variant={BUTTON_BACK_VARIANT}/>
    </div>
  )
}
