import {useState} from "react";
import {BUTTON_BACK_VARIANT, NO_HOTEL, ROOM} from "../../../commons/Globals";
import FSKBButton from "../../../components/FSKBButton";
import FSKBTitle from "../../../components/FSKBTitle";
import FSKBMessage from "../../../components/FSKBMessage";

export default function WizardStep4Hotels({ data, onClick, onClickBack, hotels }) {
  const [hotelData, setHotelData] = useState(data)
  const [message, setMessage] = useState(null)

  return (
    <div className="col">
      <FSKBTitle title={"Hotelzimmerbuchung"} titleFranz={"Réservation hôtel"} />

      <div style={{marginTop:2, marginBottom: 2}}>
        <p>
          <strong>
            1 Nacht, Freitag, 23. Juni 2023<br/>
            <span className='franz'>1 Nuit, Vendredi 23 Juin 2023</span>
          </strong>
          <small></small>
        </p>
      </div>

      <div className="form-check">
        <input type="radio" name="hotel" className="form-check-input"
               value={hotelData.id} checked={hotelData.id===NO_HOTEL}
               onChange={() => setHotelData({id: NO_HOTEL, room: ROOM.NONE.code})} />
               kein Zimmer benötigt / <span className='franz'>pas de réservation souhaitée</span>
      </div>
      <br/>
      { hotels.map(hotel => <div key={hotel.id} style={{marginTop:10}}>
          <strong>{ hotel.name }</strong>
          <br/>
          <small>Fr. {hotel.price} /Zimmer/Nacht&nbsp;<span className='franz'>/Chambre/Nuit</span></small>
          <small>&nbsp;({hotel.nrOfFreeSingleRooms}/{hotel.nrOfFreeDoubleRooms})</small>
          <div className="form-check" >
            <input type="radio" name="hotel" className="form-check-input"
                   value={hotel.id} checked={hotel.id===hotelData.id}
                   onChange={() => setHotelData({id: hotel.id, room: ROOM.EZ.code})}
                   disabled={hotel.nrOfFreeSingleRooms<=0} />
                   Einzelzimmer / <span className='franz'>Chambre single</span>
                   <small>{hotel.nrOfFreeSingleRooms<=0 ? " (ausgebucht/réservé jusqu'à)" : ""}</small>
            <br/>
            <input type="radio" value={hotel.id} name="hotel" className="form-check-input"
                   onChange={() => setHotelData({id: hotel.id, room: ROOM.DZ.code})}
                   disabled={hotel.nrOfFreeDoubleRooms<=0} />
                   Doppelzimmer / <span className='franz'>Chambre double</span>
                   <small>{hotel.nrOfFreeDoubleRooms<=0 ? " (ausgebucht/réservé jusqu'à)" : ""}</small>
          </div>
        </div>
      )}

      <div className="footer-buttons">
        <FSKBMessage message={message} />

        <FSKBButton title="zurück / retour" onClick={onClickBack} variant={BUTTON_BACK_VARIANT}/>
        <FSKBButton title="weiter / suivant" onClick={() => {
          if (hotelData && hotelData.id > 0) {
            onClick(hotelData)
          } else {
            setMessage(
              "Bitte wählen Sie ein Hotelzimmer / " +
              "Veuillez sélectionner une chambre d'hôtel.")
          }
        }} />
      </div>
    </div>
  )
}