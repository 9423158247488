import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

export const firebaseConfig = {
  apiKey: "AIzaSyA9cOb9dRWRrjgjazl1XHUDoFq7AKOJnZI",
  authDomain: "fskb-forms.firebaseapp.com",
  projectId: "fskb-forms",
  storageBucket: "fskb-forms.appspot.com",
  messagingSenderId: "912418963843",
  appId: "1:912418963843:web:5033d82cf4bd8ec03976b6",
  measurementId: "G-BY9FQT44QY"
}

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
