


export default function FSKBMessage({message}) {
  return (
    <>
      { message &&
        <div className="alert alert-danger">
          {message}
        </div>
      }
    </>
  )
}