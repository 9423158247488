import React, {useEffect, useState} from "react";
import {
  ACTUAL_FORM,
  getHotelFromId,
  getHotelFromIdMeeting,
  getRoomFromId,
  getTextForYesNo
} from "../../commons/Globals";
import {getRegistrations} from "../../services/registrationService";
import {getHotels} from "../../services/hotelService";

export default function RegistrationView() {
  const [registrations, setRegistrations] = useState([])
  const [hotels, setHotels] = useState([])

  useEffect(() => {
    getHotels(ACTUAL_FORM, (hotels) => setHotels(hotels))
    getRegistrations(ACTUAL_FORM, (registrations) => {
      setRegistrations(registrations)
    })
  }, [])

  function VariableView({title, variable}) {
    return (
      <>
        <small>{title}:<br/></small>
        {variable}
        <br/>
      </>
    )
  }

  return (
    <div className={"container"}>
      <div className={"row"}>
        <div className={"col-12"}>
          <h2>Anmeldungen</h2>
          <table className="table table-responsiv table-striped table-sm" style={{marginTop:40}}>
            <thead>
            <tr>
              <th>Anmeldung</th>
              <th>Programm</th>
              <th>Firmendaten</th>
              <th>Leiter Konferenz</th>
            </tr>
            </thead>
            <tbody>
            {registrations.map((registration, i) =>
              <tr key={"tr-" + i}>
                <td>
                  {registration.personalDetails.firstname}
                  &nbsp;
                  {registration.personalDetails.lastname}
                  <br/>
                  {registration.personalDetails.street}
                  <br/>
                  {registration.personalDetails.zipCode}
                  &nbsp;
                  {registration.personalDetails.city}
                  <br/>
                  {registration.personalDetails.email}
                  <br/>
                  {registration.personalDetails.phone}
                  <br/><br/>
                  <small>{formattedDate(registration.registration.date)}</small>
                </td>
                <td>
                  <small>MV SÜGB:</small> {getTextForYesNo(registration.program.question1)}
                  <br/>
                  <small>Mittagessen Freitag:</small> {getTextForYesNo(registration.program.question2)}
                  <br/>
                  <small>MV FSKB:</small> {getTextForYesNo(registration.program.question3)}
                  <br/>
                  <small>Aperitif Freitagabend:</small> {getTextForYesNo(registration.program.question4)}
                  <br/>
                  <small>Abendessen Freitag:</small> {getTextForYesNo(registration.program.question5)}
                  <br/>
                  <small>Ausflug am Samstag:</small> {getTextForYesNo(registration.program.question6)}
                  <br/>
                  <small>Hotel:</small>
                  <br/>
                  {getHotelFromId(registration.hotel.id, hotels)}
                  <br/>
                  {getRoomFromId(registration.hotel.room)}
                  <br/><br/>
                  <small>Bemerkungen:</small><br/>
                  {registration.program.remarks}
                </td>
                <td>
                  <VariableView
                    title={"Firma"}
                    variable={registration.personalDetails.company} />
                  <VariableView
                    title={"Rechnungsadresse oder R-E-Mail"}
                    variable={registration.personalDetails.invoiceAddress} />
                  <VariableView
                    title={"Kostenstelle"}
                    variable={registration.personalDetails.costCenter} />
                </td>
                <td>
                  Vorstandssitzung: {getTextForYesNo(registration.meeting.meeting1)}
                  <br/>
                  Präs.- und Gesch.führer.konf.: {getTextForYesNo(registration.meeting.meeting2)}
                  <br/>
                  Abendessen: {getTextForYesNo(registration.meeting.dinner)}
                  <br/>
                  { registration.hotel !== null
                    ? <>
                        <small>Hotel:</small><br/>
                        {getHotelFromIdMeeting(registration.meeting.hotel.id, hotels)}
                        <br/>
                        {getRoomFromId(registration.meeting.hotel.room)}
                      </>
                    : "kein Hotel gebucht"}
                </td>
              </tr>
            )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}


function formattedDate(d) {
  return new Date(d).getDay() + "." +
    new Date(d).getMonth() + "." +
    new Date(d).getFullYear() + " " +
    new Date(d).getHours() + ":" +
    new Date(d).getMinutes() + ":" +
    new Date(d).getSeconds()
}