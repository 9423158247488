import React, {useEffect, useState} from "react";
import {getHotels} from "../../services/hotelService";
import {ACTUAL_FORM} from "../../commons/Globals";

export default function HotelsView() {
  const [hotels, setHotels] = useState([])

  useEffect(() => {
    getHotels(ACTUAL_FORM, (hotels) => setHotels(hotels))
  }, [])

  return (
    <div className={"container"}>
      <div className={"row"}>
        <div className={"col-12"}>
          <h2>Hotelübersicht</h2>
          <table className="table table-responsiv table-striped table-sm">
            <thead>
              <tr>
                <th>Hotel</th>
                <th>Einzelzimmer</th>
                <th>Doppelzimmer</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            {hotels.map(hotel =>
              <tr key={"tr-"+hotel.id}>
                <td>{hotel.name}</td>
                <td>
                  {hotel.nrOfFreeSingleRooms} von {hotel.nrOfSingleRooms} frei
                  &nbsp;
                  { hotel.nrOfFreeSingleRooms===0 &&
                    <div className={"alert alert-danger alert-sm"} style={{display:'inline-block', padding: 3}}>
                      ausgebucht
                    </div>
                  }
                </td>
                <td>
                  {hotel.nrOfFreeDoubleRooms} von {hotel.nrOfDoubleRooms} frei
                  &nbsp;
                  { hotel.nrOfFreeDoubleRooms===0 &&
                    <div className={"alert alert-danger alert-sm"} style={{display:'inline-block', padding: 3}}>
                      ausgebucht
                    </div>
                  }
                </td>
                <td>
                </td>
              </tr>
            )}
            </tbody>
          </table>
        </div>
    </div>
  </div>
)}