import {getHotelFromId, getHotelFromIdMeeting, getRoomFromId, getTextForYesNo} from "../commons/Globals";
import {getQuestion6Answer, YES_NO_QUESTIONS_VALUES} from "../pages/mainform/wizard/WizardStep3Program";

const SECURITY_KEY = "a7f159d8-a3a4-455a-bae6-a18e54776129";

export const sendMail = (mailto, mailsubject, data, hotels) => {
  const formData = new FormData();
  formData.append('mailto', mailto);
  formData.append('mailsubject', mailsubject);
  formData.append('mailmessage', formatBody(data, hotels));
  formData.append('uid', SECURITY_KEY);

  fetch('https://forms.fskb.ch/send_mail.php', {
    method: 'POST',
    body: formData,
  })
    .then((response) => response.json())
    .then((result) => {
      console.log('Success:', result);
    })
    .catch((error) => {
      console.error('Error:', error);
    })
}

 function formatBody(data, hotels) {
  let body =
    "<!DOCTYPE html>" +
    "<html lang='en'>" +
    "<head>" +
    "  <style>" +
    "    table { border: 1px solid gray; border-collapse: collapse} " +
    "    td { border: 1px solid gray; padding: 6px; } "  +
    "  </style>" +
    "</head>" +

    "<body>" +
    "<p>Sehr geehrte Frau, sehr geehrter Herr<br/>" +
    "Besten Dank für Ihre Anmeldung an die Kies- und Betontage 2023.\nSie erhalten in den kommenden Tagen eine definitive Anmeldebestätigung per Mail.\n" +
    "Freundliche Grüsse<br/>" +
    "<br/>" +
    "Madame, Monsieur,<br/>" +
    "Nous vous remercions de votre inscription pour les journées Gravier et Béton 2023. Vous recevrez une confirmation d’inscription définitive dans les prochains jours par courriel.\n" +
    "Meilleures salutations<br/>" +
    "</p>" +
    "<br/>" +

    "<h2>Personalangaben / Données personnelles:</h2>" +
    "<table>" +
    "<tr><td>" + "Name / Nom: " + "</td><td>" + data.personalDetails.lastname + "</td></tr>" +
    "<tr><td>" + "Vorname / Prénom: " + "</td><td>" + data.personalDetails.firstname + "</td></tr>" +
    "<tr><td>" + "Firma / Entreprise: " + "</td><td>" + data.personalDetails.company + "</td></tr>" +
    "<tr><td>" + "Strasse / Rue: " + "</td><td>" + data.personalDetails.street + "</td></tr>" +
    "<tr><td>" + "PLZ / NP: " + "</td><td>" + data.personalDetails.zipCode + "</td></tr>" +
    "<tr><td>" + "Ort / Lieu: " + "</td><td>" + data.personalDetails.city + "</td></tr>" +
    "<tr><td>" + "E-Mail: " + "</td><td>" + data.personalDetails.email + "</td></tr>" +
    "<tr><td>" + "Telefonnummer / No de téléphone: " + "</td><td>" + data.personalDetails.phone + "</td></tr>" +
    "<tr><td>" + "Rechnungsadresse / No de téléphone: " + "</td><td>" + data.personalDetails.invoiceAddress + "</td></tr>" +
    "<tr><td>" + "Kostenstelle / Centre de coûts: " + "</td><td>" + data.personalDetails.costCenter + "</td></tr>" +
    "</table>" +
    "<br/>" +

    "<h2>Programm / Programme:</h2>" +
    "<table>" +
    "<tr><td>" + YES_NO_QUESTIONS_VALUES[0].title + ": " + "</td><td>" + getTextForYesNo(data.program.question1) + "</td></tr>" +
    "<tr><td>" + YES_NO_QUESTIONS_VALUES[1].title + ": " + "</td><td>" + getTextForYesNo(data.program.question2) + "</td></tr>" +
    "<tr><td>" + YES_NO_QUESTIONS_VALUES[2].title + ": " + "</td><td>" + getTextForYesNo(data.program.question3) + "</td></tr>" +
    "<tr><td>" + YES_NO_QUESTIONS_VALUES[3].title + ": " + "</td><td>" + getTextForYesNo(data.program.question4) + "</td></tr>" +
    "<tr><td>" + YES_NO_QUESTIONS_VALUES[4].title + ": " + "</td><td>" + getTextForYesNo(data.program.question5) + "</td></tr>" +
    "<tr><td>" + "Teilnahme am Ausflug am Samstag: " + "</td><td>" + getQuestion6Answer(data.program.question6) + "</td></tr>" +
    "<tr><td>" + "Hotel / Réservation hôtel: " + "</td><td>" + getHotelFromId(data.hotel.id, hotels) +
         " (" + getRoomFromId(data.hotel.room) + "</td></tr>" +
    "<tr><td>" + "Bemerkungen / Remarques: " + "</td><td>" + data.program.remarks + "</td></tr>" +
    "</table>" +
    "<br/>" +

    "<h2>Teilnahme Vorstand / Präsidenten- und Geschäftsführerkonferenz:<br/>" +
    "Participation séance comité / Conférence des présidents et directeurs" + "</h2>" +
    "<table>" +
    "<tr><td>" + "Teilname Vorstandssitzung / Participation à la séance du comité ASGB: " +
      "</td><td>" + getTextForYesNo(data.meeting.meeting1) + "</td></tr>" +

    "<tr><td>" + "Teilname Präsidenten- und Geschäftsführerkonferenz / " +
    "Participation séance comité / Conférence des présidents et directeurs: " +
      "</td><td>" + getTextForYesNo(data.meeting.meeting2) + "</td></tr>" +

    "<tr><td>" + "Teilnahme Abendessen nach der Konferenz / " +
    "Participation au souper aprés la conférence: " +
    "</td><td>" + getTextForYesNo(data.meeting.dinner) + "</td></tr>" +

    "<tr><td>" + "Hotel: " + getHotelFromIdMeeting(data.meeting.hotel.id) +
      "</td><td>" +
         " (" + getRoomFromId(data.meeting.hotel.room) + ")</td></tr>" +
    "</table>" +
    "<br/>" +

    "<p>FSKB Fachverband der Schweizerischen Kies- und Betonindustrie<br/>" +
    "ASGB Association Suisse de l’industrie des Graviers et du Béton</p>"

  return body
}



