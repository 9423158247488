


export default function FSKBTitle({title, titleFranz}) {
  return (
    <h2 style={{marginBottom: 40}}>
      {title}
      <br/>
      <span className="franz">{titleFranz}</span>
    </h2>
  )
}