import {useEffect, useState} from "react";
import {isSignedIn, signInUser, signOutUser} from "../../services/authenticationService";
import {registerObserver, unregisterObserver} from "../../commons/persistence";
import {BrowserRouter, Link, Route, Routes} from "react-router-dom";
import LoginView from "./LoginView";
import HotelsView from "./HotelsView";
import RegistrationView from "./RegistrationView";

function TopNavigation({navigation}) {
  const [isLoggedIn, setIsLoggedIn] = useState(isSignedIn())
  return (
    <>
      <div className="blue-bar">
        {isLoggedIn &&
          <>
            <p>
              <Link to={'/backoffice/registrations'}>Anmeldungen</Link>
            </p>
            <p>
              <Link to={'/backoffice/hotels'}>Hotelübersicht</Link>
            </p>
            <p>
              <Link to={'/backoffice'}
                    onClick={()=>signOutUser(()=>console.log("logout user"))}>
                Abmelden
              </Link>
            </p>
          </>
        }
      </div>
    </>
  )
}

function BackOfficeView() {
  return (
    <div className={"container"}>
      <div className={"row"}>
        <div className={"col-12"}>
          <h2>Back office</h2>
          <small>Verwaltung der Anmeldungen</small>
        </div>
      </div>
    </div>
  )
}

export default function BackOffice() {
  const [signedIn, setSignedIn] = useState(isSignedIn())

  useEffect(() => {
    registerObserver("XXX", (accessToken) => {
      setSignedIn(accessToken !== null)
      return () => { unregisterObserver("XXX")}
    })
  }, [])

  return (
    <BrowserRouter>
      <TopNavigation />

      <Routes>
        <Route path="/backoffice" element={
          <>
            { !signedIn && <LoginView /> }
            { signedIn && <BackOfficeView /> }
          </>
        }/>
        <Route path="/backoffice/registrations" element={
          <>{ isSignedIn() ? <RegistrationView/> : <LoginView /> }</>
        }/>
        <Route path="/backoffice/hotels" element={
          <>{ isSignedIn() ? <HotelsView /> : <LoginView /> }</>
        }/>
      </Routes>

    </BrowserRouter>
  )
}