import {useEffect, useState} from "react";
import {BUTTON_BACK_VARIANT, hotelsMeeting, JA, NEIN, NO_HOTEL, ROOM} from "../../../commons/Globals";
import FSKBButton from "../../../components/FSKBButton";
import FSKBTitle from "../../../components/FSKBTitle";
import FSKBMessage from "../../../components/FSKBMessage";

export default function WizardStep5Specials({ data, onClick, onClickBack }) {
  const [meeting1, setMeeting1] = useState(data.meeting1)
  const [meeting2, setMeeting2] = useState(data.meeting2)
  const [dinner, setDinner] = useState(data.dinner)

  // TODO: Hier müsste theoretisch ein Array sein
  const [hotelRoom, setHotelRoom] = useState(data.hotel)

  const [message, setMessage] = useState(null)

  useEffect(() => {
    setMessage(null)
  }, [meeting1, meeting2, dinner, hotelRoom])

  return (
    <div className="col">
      <FSKBTitle title={"Teilnahme Vorstand / Präsidenten- und Geschäftsführerkonferenz"}
                 titleFranz={"Participation séance comité / Conférence des présidents et directeurs"} />

      <div style={{marginTop:0, marginBottom: 20}}>
        <strong>
          vom Donnerstag 22. Juni 2023<br/>
          <span className='franz'>du Jeudi 22 Juin 2023</span>
        </strong>
        <div style={{border: '1px solid gray', padding: 10, marginTop: 20, borderRadius: 6}}>
          Anmeldung nur für Vorstandsmitglieder, Präsidenten-, Geschäftsführer und Vorstandsmitglieder der Kantonalverbände möglich.
          <br/>
          <span className='franz'>Inscription uniquement possible pour membres du comité ASGB et présidents, directeurs et représentants des associations cantonales.</span>
        </div>
      </div>
      <br/>

      <strong>
        Teilnahme an der Vorstandssitzung (09:00-12:00 Uhr)<br/>
        <span className='franz'>Participation à la séance du comité ASGB (09:00-12:00 Uhr)</span>
      </strong>
      <br/>
      <div className="form-check">
        <input type="radio" name="vorstandsitzung" className="form-check-input"
               value={JA} checked={meeting1===JA}
               onChange={e => setMeeting1(parseInt(e.target.value))} />
        <label className="form-check-label" htmlFor="vorstandsitzung">ja / oui</label>
      </div>
      <div className="form-check">
        <input type="radio" name="vorstandsitzung" className="form-check-input"
               value={NEIN} checked={meeting1===NEIN}
               onChange={e => setMeeting1(parseInt(e.target.value))} />
        <label className="form-check-label" htmlFor="vorstandsitzung">nein / non</label>
      </div>
      <br/>

      <strong>
        Teilnahme an der Präsidenten- und Geschäftsführerkonferenz (14:00-17:00 Uhr)<br/>
        <span className='franz'>Participation séance comité / Conférence des présidents et directeurs (14:00-17:00 Uhr)</span>
      </strong>
      <br/>
      <div className="form-check">
        <input type="radio" name="praesident" className="form-check-input"
               value={JA} checked={meeting2===JA}
               onChange={e => setMeeting2(parseInt(e.target.value))} />
        <label className="form-check-label" htmlFor="praesident">ja / oui</label>
      </div>
      <div className="form-check">
        <input type="radio" name="praesident" className="form-check-input"
               value={NEIN} checked={meeting2===NEIN}
               onChange={e => setMeeting2(parseInt(e.target.value))} />
        <label className="form-check-label" htmlFor="praesident">nein / non</label>
      </div>
      <br/>

      <strong>
        Teilnahme Abendessen nach der Konferenz<br/>
        <span className='franz'>Participation au souper aprés la conférence</span>
      </strong>
      <br/>
      <div className="form-check">
        <input type="radio" name="abendessen" className="form-check-input"
               value={JA} checked={dinner===JA}
               onChange={e => setDinner(parseInt(e.target.value))} />
        <label className="form-check-label" htmlFor="abendessen">ja / oui</label>
      </div>
      <div className="form-check">
        <input type="radio" name="abendessen" className="form-check-input"
               value={NEIN} checked={dinner===NEIN}
               onChange={e => setDinner(parseInt(e.target.value))} />
        <label className="form-check-label" htmlFor="abendessen">nein / non</label>
      </div>
      <br/>

      { (meeting1===JA || meeting2===JA) && <div>
        <br/>
        <br/>
        <div style={{marginTop: 20, marginBottom: 20}}>
          <h3>
            Hotelzimmerbuchung<br/>
            <span className='franz'>Réservation Hôtel</span>
          </h3>
          <p>
            1 Nacht, Donnerstag 22. Juni 2023<br/>
            <span className='franz'>1 Nuit, Jeudi 22 Juin 2023</span>
          </p>
        </div>

        { hotelsMeeting.map(hotel => <div key={hotel.id}>
            <strong>{hotel.name}</strong>
            <br/>
            <small>Fr. {hotel.price} /Zimmer/Nacht&nbsp;<span className='franz'>Chambre/Nuit</span></small>
            <div className="form-check">
              <input type="radio" name="hotel" className="form-check-input"
                     value={ROOM.EZ.code} checked={hotelRoom.room===ROOM.EZ.code}
                     onChange={() => setHotelRoom({ id: hotel.id, room: ROOM.EZ.code })} />
              Einzelzimmer / <span className='franz'>Chambre single</span>
              <br/>
              <input type="radio" name="hotel" className="form-check-input"
                     value={ROOM.DZ.code} checked={hotelRoom.room===ROOM.DZ.code}
                     onChange={() => setHotelRoom({ id: hotel.id, room: ROOM.DZ.code })} />
              Doppelzimmer / <span className='franz'>Chambre double</span>
              <br />
            </div>
          </div>
        )}
        </div>
      }

      <div className="footer-buttons">
        <FSKBMessage message={message} />

        <FSKBButton title="zurück / retour" onClick={onClickBack} variant={BUTTON_BACK_VARIANT}/>
        <FSKBButton title="weiter / suivant" onClick={() => {
          if (meeting1===NEIN && meeting2===NEIN && dinner===NEIN) {
            onClick({meeting1, meeting2, dinner, hotel: { id: NO_HOTEL, room: ROOM.NONE.code }})

          } else if (
            (meeting1===JA && meeting2===JA && hotelRoom.id!==NO_HOTEL) ||
            (meeting1===JA && meeting2===NEIN && hotelRoom.id!==NO_HOTEL) ||
            (meeting1===NEIN && meeting2===JA && hotelRoom.id!==NO_HOTEL)) {
            onClick({meeting1, meeting2, dinner, hotel: hotelRoom})

          } else {
            setMessage(
              "Bitte füllen Sie das Formular vollständig aus / " +
              "Veuillez remplir complètement le formulaire.")
          }

        }} />
      </div>
    </div>
  )
}
