import FSKBButton from "../../../components/FSKBButton";
import FSKBTitle from "../../../components/FSKBTitle";

export default function WizardStep1Explanations({ onClick }) {
  return (
    <div className="col">
      <FSKBTitle title={"Anmeldung Kies- und Betontage 2023"}
                 titleFranz={"Inscription Journées gravier et béton 2023"} />

      <div className="footer-buttons">
        <FSKBButton title="weiter / suivant" onClick={onClick} />
      </div>
    </div>
  )
}
