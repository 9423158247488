import React from "react"


export default function FSKBButton({title, onClick, variant}) {
  return (
    <input type="submit"
           onClick={ e => onClick(e)}
           value={title}
           className={"btn  " + (variant ? variant : "btn-secondary")}
           style={{marginRight: 10}}
    />
  )
}

