import {clearItem, getItem, setItem} from "../commons/persistence";
import {getAuth, signInWithEmailAndPassword, signOut} from "firebase/auth";

export const ACCESS_TOKEN="@ACCESS_TOKEN"

export const isSignedIn = () => {
  let accessToken = getItem(ACCESS_TOKEN)
  return accessToken !== null
}

export const signInUser = (username, password, callback) => {
  const auth = getAuth();
  // "rlanza@clip.ch", "abc.123456"
  signInWithEmailAndPassword(auth, username, password)
    .then((userCredential) => {
      const user = userCredential.user;
      if (callback) callback(user.accessToken)
      setItem(ACCESS_TOKEN, user.accessToken)
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      if (callback) callback(undefined, errorCode, errorMessage)
    });
}

export const signOutUser = (callback) => {
  const auth = getAuth();
  signOut(auth).then(() => {
    clearItem(ACCESS_TOKEN)
    if (callback) callback(true)
  }).catch((error) => {
    clearItem(ACCESS_TOKEN)
    if (callback) callback(false, error)
  });
}
